/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { orgName } from '../../../../app/pages/layout-builder/org'

// type Props = {
//   className: string
// }
type Props = {
  className: string
  userDetails: TeamMemberObject[]
  gdriveInfo: GDriveObject[]
  setGDriveAnalytics: (token: string[]) => void
  setFormData: (driveInfo: GDriveObject) => void
  setUpdate: (state: boolean) => void;
}

interface TeamMemberObject {
  username: string;
  email: string;
  organizationId: number;
  type: string;
  password: string
  organization_name: string
}

interface GDriveObject {
  id: number
  source_folder: string,
  destination_folder: string,
  useremail: string,
  username: string,
  assign_to: string,
  template: string,
  organisation: number,
  source_folder_name: string,
  destination_folder_name: string
}


const DriveTable: React.FC<Props> = ({ className, userDetails, gdriveInfo, setGDriveAnalytics, setFormData, setUpdate }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>Source Folder</th>
                <th className='min-w-125px'>Destination Folder</th>
                <th className='min-w-125px'>Assigned To</th>
                <th className='min-w-125px'>Template</th>
                <th className='min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                gdriveInfo.map((gdriveFolder) => (
                  <tr>
                    <td className='px-3'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <KTIcon iconName='folder' className="fs-2x text-dark" />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {gdriveFolder.source_folder_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {gdriveFolder.destination_folder_name}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {gdriveFolder.assign_to}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {gdriveFolder.template}
                      </a>
                    </td>
                    <td className='d-flex  justify-content-end'>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary me-3 my-3 w-180px"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_team"
                        onClick={(e) => {
                          setUpdate(true);
                          setFormData({
                            ...gdriveFolder,
                            destination_folder: 'https://drive.google.com/drive/folders/' + gdriveFolder.destination_folder + '?usp=drive_link',
                            source_folder: 'https://drive.google.com/drive/folders/' + gdriveFolder.source_folder + '?usp=drive_link',
                          });
                        }}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary me-3 my-3 w-180px "
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_team_analytics"
                        onClick={(e) => setGDriveAnalytics([gdriveFolder.source_folder, gdriveFolder.destination_folder])}
                      >
                        View Analytics
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
            {/* <tbody>
              {userDetails.map((user, index) => (
                <tr>
                  <td className='px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <KTIcon iconName='user' className="fs-3x text-dark" />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user.username}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.email}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.type}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.organization_name}
                    </a>
                  </td>
                  <td className='d-flex  justify-content-end'>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-3 my-3 w-180px "
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_team"
                      onClick={(e) => setUpdateUser(user)}
                    >
                      Edit Member
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-3 my-3 w-180px "
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_team_analytics"
                      onClick={(e) => setUserAnalytics(user)}
                    >
                      View Analytics
                    </button>
                  </td>
                </tr>
              ))}
            </tbody> */}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { DriveTable }
