import React, { useCallback, useEffect, useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { Blocks } from "react-loader-spinner";
import { pdfjs } from "react-pdf";
import { KTIcon } from "../../../_metronic/helpers";
import { fileTextExtraction } from "../../api";
import { useAuth } from "../../modules/auth";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

export default function TextExtraction({}: Props) {
  const { currentUser } = useAuth();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [pdfFile, setPdfFile] = useState<File>();

  const [url, setUrl] = useState("");
  const [selectedCsv, setSelectedCsv] = useState([{}]);
  const [result, setResult] = useState<MyObject>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(true);
  const [pdfUrl, setPdfUrl] = useState<string>(
    "https://example.com/path-to-your-pdf-file.pdf"
  );
  const [fileName, setFileName] = useState<string>("");

  const [sasUrl, setSasUrl] = React.useState("");

  const handleDownload = () => {};

  const handleProcess = (event: React.FormEvent) => {
    event.preventDefault();
    setResult({});
    setProcessing(true);
    const formData = new FormData();
    if (pdfFile) {
      formData.append("files", pdfFile, `${pdfFile.name}`);
    }

    fileTextExtraction(pdfFile ? formData : { url: "https://" + url })
      .then((response) => {
        console.log(response);
        setResult(response.data.data[0][0]);
        setProcessing(false);
      })
      .then((data) => {})
      .catch((error) => {
        // Handle error
      });
  };

  const handlePreview = async () => {
    setPreview(!preview);
  };

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Handle file changes here
      console.log(acceptedFiles);
      if (acceptedFiles) {
        setFileName(acceptedFiles[0].name);
        let seletedFile = acceptedFiles[0];
        setPdfFile(seletedFile);
        const fileList = Array.from(acceptedFiles);
        setSelectedFiles(fileList);
      }
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (url != "") {
      setPdfFile(undefined);
      setFileName("");
      setResult({});
      let fileInput = window.document.getElementById("file-input");
    }
  }, [url]);

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form
                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework mb-5"
                noValidate
                id="kt_login_signup_form"
              >
                {/* <label className="form-label fw-bolder text-dark fs-6">
                  Search by URL
                </label>
                <div className="d-flex align-items-center justify-content-between position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-md-750px w-lg-900px ps-14 border border-dark border-2"
                    placeholder="Enter your domain"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">
                    Or
                  </span>
                </div> */}
                <div
                  {...getRootProps()}
                  style={{
                    border: "2px dashed",
                    padding: "100px 40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#1b1b29"
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>
                      {fileName == "" ? (
                        <>
                          Drag media here to upload or connect an account...
                          <br />
                          <button>Google Drive</button>
                          <button>One Drive</button>
                        </>
                      ) : (
                        <div className="badge badge-primary">
                          <KTIcon iconName="file" className="fs-3" /> &nbsp;{" "}
                          {fileName}
                        </div>
                      )}
                    </p>
                  )}
                </div>
                {/* <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">
                    Or
                  </span>
                </div> */}

                {/* <div className="row g-3 mb-9">
                  <div className="col-md-12">
                    <Link
                      to="/settings/connections"
                      className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                      Connect to an external datasource
                    </Link>
                  </div>
                </div> */}
              </form>

              <div className="mt-5 text-center">
                <button
                  className="btn btn-lg btn-success w-100"
                  data-bs-dismiss="modal"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{borderRadius: '0px'}}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5 mb-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark mb-2">
              {" "}
              Keywords Extraction{" "}
            </span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
              Search your <strong> files</strong>
            </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin: Btn Row */}
        <div className="row">
          <div
            className="d-flex justify-content-between my-1"
            data-kt-user-table-toolbar="base"
          >
            <div className="px-5">
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_assosiate_student"
              >
                <KTIcon iconName="plus" className="fs-3" />
                Upload Document
              </button>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                // data-bs-toggle="modal"
                // data-bs-target="#kt_modal_assosiate_student"
                onClick={handlePreview}
              >
                {!preview ? "Preview Document" : "Normal Mode"}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleProcess}
              >
                <KTIcon iconName="gear" className="fs-1" />
                Process
              </button>

              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-200px"
                onClick={handleDownload}
              >
                <KTIcon iconName="file" className="fs-1" />
                Download PDF
              </button>
              {Object.keys(result).length === 0 ? null : (
                <>
                  <button
                    className="btn btn-success me-3 my-3 w-150px"
                    id="kt_drawer_chat_toggle"
                  >
                    <KTIcon iconName="message-text-2" className="fs-1" />
                    Chat
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end :: Btn Row */}

        <div className="card-body pt-5">
          <div className="row d-flex justify-content-between position-relative">
            {/* File Preview UI */}
            {preview && (
              <div className="col-sm-12 col-md-6">
                <div
                  className={`card card-xl-stretch mb-5 mb-xl-8`}
                  style={{ height: "100%" }}
                >
                  <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">
                      <KTIcon
                        iconName="screen"
                        className="fs-2x text-primary"
                      />
                      &nbsp; Preview Document
                    </h3>
                  </div>
                  {pdfFile && (
                    <iframe
                      src={URL.createObjectURL(pdfFile)}
                      title="Preview"
                      width="100%"
                      height="500px"
                    />
                  )}
                  {url && (
                    <iframe
                      src={"https://" + url}
                      title="Preview"
                      width="100%"
                      height="500px"
                    />
                  )}
                </div>
              </div>
            )}

            {/* Result UI */}
            <div className={`col-sm-12 ${preview ? "col-md-6" : "col-md-12"}`}>
              {Object.keys(result).length === 0 ? (
                <div className="row align-items-center">
                  {processing && (
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                    />
                  )}
                </div>
              ) : (
                <div
                  className="card-xl-stretch mb-5 mb-xl-8 px-5"
                  style={{ height: "100%" }}
                >
                  <div className="rounded p-3 bg-dark text-light overflow-auto">
                    <h3 className="pb-3 text-light">RESULT:</h3>

                    {/* Result Normal Ky value */}
                    {Object.entries(result).map(([key, value]) => (
                      <div key={key}>
                        {typeof value != "object" && (
                          <h6 className="text-light">
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {key}
                            </span>{" "}
                            : &nbsp;
                            <span>{JSON.stringify(value)}</span>
                          </h6>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
