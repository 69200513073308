import { Link } from 'react-router-dom'
import { DocumentTypeCard } from '../../modules/dashboard/DocumentTypeCard'

type Props = {}

const data = [
  {
    link: "invoice",
    svgIcon: "document",
    title: "Invoices",
    description: "Extract invoice ID, customer details, vendor details, ship to, bill to, total tax, subtotal, line items and more.",
    disabbled: false
  },
  {
    link: "bankstatement",
    svgIcon: "cheque",
    title: "Bank Statements",
    description: "Extract customer ID, customer details, account statement and Dump into csv, sql table etc",
    disabbled: false
  },
  {
    link: "handwritten",
    svgIcon: "message-text",
    title: "Handwritten",
    description: "Extract key value pairs and structure like tables and selection marks from any form or document.",
    disabbled: false
  },
  {
    link: "fax",
    svgIcon: "note",
    title: "Fax",
    description: "Extract invoice ID, customer details, vendor details and contact details from Fax documents.",
    disabbled: false
  },
  {
    link: "",
    svgIcon: "some-files",
    title: "Vouchers",
    description: "Extract Amount and Gift from Vouchers and vendor details from Gift Cards, and Coupons.",
    disabbled: true
  },
  {
    link: "",
    svgIcon: "receipt-square",
    title: "Receipt",
    description: "Extract receipt ID, customer details, amount details and vendor details from receipts.",
    disabbled: true
  },

]

export default function DocumentProcessingDashboard({ }: Props) {
  return (
    <div className='m-3'>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <h1>Document Types</h1>
        {
          data.map((doctype) => (
            <div className='col-xl-4'>
              <Link to={(doctype.link === "bankstatement" || doctype.link === "invoice" || doctype.link === "handwritten") ? `/documentprocessing/${doctype.link}` : "#"}>
                <DocumentTypeCard
                  className='card-xl-stretch mb-xl-8'
                  tag={doctype.link}
                  svgIcon={doctype.svgIcon}
                  color='light'
                  iconColor='white'
                  title={doctype.title}
                  titleColor='dark'
                  description={doctype.description}
                  descriptionColor='dark'
                />
              </Link>
            </div>
          ))
        }
      </div>
      {/* end::Row */}
    </div>
  )
}