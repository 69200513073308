/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Dropdown1} from '../../../_metronic/partials'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

type MyObject = {
  [key: string]: string[]
}

type Props = {
  searchResult: Array<MyObject>
}

const Semantic: FC<Props> = ({searchResult}) => {
  const [content, setContent] = useState<any>()
  const [copied, setCopied] = useState(false)
  const [resumeFileName, setResumeFileName] = useState('')

  const handleCopy = () => {
    navigator.clipboard.writeText(content)
    setCopied(true)
  }

  return (
    <div
      className='rounded p-5 bg-dark text-light overflow-auto h-100 border border-3 border-primary'
      style={{maxHeight: '70vh', overflowY: 'scroll'}}
    >
      {/* View More */}
      <div className='modal fade' id='kt_modal_preview' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Document Summary</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y rounded m-4'>
              <div
                className='rounded mt-5 p-4 bg-light text-dark'
                style={{
                  height: '60vh',
                  overflowY: 'scroll',
                  // position: "relative"
                }}
              >
                <div
                  className={`badge ${copied ? 'badge-success' : 'badge-primary'}`}
                  onClick={handleCopy}
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '30px',
                    cursor: 'pointer',
                  }}
                >
                  <KTIcon iconName={copied ? 'archive-tick' : 'copy'} className='fs-1' />
                </div>
                <div className='' style={{position: 'relative'}}>
                  <p>{content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preview Document */}
      <div className='modal fade' id='kt_modal_resume' aria-hidden='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Preview Resume</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y'>
              <div>
                <iframe
                  src={`https://formrecognizer12345.blob.core.windows.net/resumesearchstorage/${resumeFileName}`}
                  title='PDF Preview'
                  width='100%'
                  height='500px'
                ></iframe>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {searchResult.length > 0 && (
        <div className='rounded mt-5 p-3 bg-dark text-light'>
          {searchResult.map((sResult) => (
            <div className='card mb-5 mb-xl-8'>
              <div className='card-body pb-0'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/svg/files/pdf.svg')} alt='' />
                    </div>

                    <div className='d-flex flex-column'>
                      <a
                        href='#'
                        className='text-gray-800 text-hover-primary fs-6 fw-bold'
                        onClick={(e) => {
                          setResumeFileName(sResult.filePath.toLocaleString())
                        }}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_resume'
                      >
                        {sResult.filePath.slice(0, sResult.filePath.indexOf('.'))}
                      </a>

                      <span className='text-gray-400 fw-semibold'>
                        Score : {Number(sResult['score']).toFixed(2)}
                      </span>
                    </div>
                  </div>

                  <div className='my-0'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_preview'
                      onClick={(e) => {
                        setCopied(false)
                        setContent(sResult.summary)
                      }}
                    >
                      <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <Dropdown1 />
                  </div>
                </div>

                <div className='mb-5'>
                  <p className='text-gray-800 fw-normal mb-5'>
                    {sResult.summary.length > 200
                      ? sResult.summary.slice(0, 200) + '...'
                      : sResult.summary}
                  </p>

                  {/* <div className="d-flex align-items-center mb-5">
                    {sResult.keyphrases.slice(0, 4).map((keyphrase) => (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                      >
                         <KTIcon iconName="message-text-2" className="fs-2" /> 
                        {keyphrase}
                      </a>
                    ))}
                  </div> */}
                </div>

                <div className='separator mb-4'></div>

                <form className='position-relative mb-6'>
                  <textarea
                    className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                    rows={1}
                    placeholder=''
                  ></textarea>

                  <div className='position-absolute top-0 end-0 me-n5'>
                    <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                      <KTIcon iconName='paper-clip' className='fs-3 mb-3' />
                    </span>

                    <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                      <KTIcon iconName='geolocation' className='fs-2 mb-3' />
                    </span>
                  </div>
                </form>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export {Semantic}
