import React, { useEffect, useState } from 'react'
import { Blocks } from 'react-loader-spinner'
import { utils, writeFile } from 'xlsx'
import { KTIcon } from '../../../_metronic/helpers'
import { filesByUser, sharmanSemantic } from '../../api'
import { useAuth } from '../../modules/auth'
import { UploadDocuments } from '../../modules/documentprocessing/UploadDocuments'
import { Semantic } from '../../modules/results/Semantic'

type Props = {}

type fileType = Array<string>

const bgList = ['bg-warning']

type MyObject = {
  [key: string]: string[]
}

type FileObject = {
  filePath: string
  url: string
}

const dummy = [
  { name: 'doc 1', keyword: ['word 1', 'word 2'] },
  { name: 'doc 2', keyword: ['word 3', 'word 4'] },
]

export default function SemanticSearch({ }: Props) {
  const { currentUser } = useAuth()

  // const [folder, setFolder] = useState<Array<FileObject>>();
  const [folder, setFolder] = useState({
    folderName: '',
    files: [
      {
        blobLocation: '',
        containerName: '',
        fileOriginalName: '',
        fileUrl: '',
        processed: 0,
        tag: '',
        userEmail: '',
        createdAt: '',
      },
    ],
  })
  const [selectedFiles, setSelectedFiles] = useState<fileType>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedCsv, setSelectedCsv] = useState([{}])
  const [result, setResult] = useState<Array<MyObject>>([{}])
  const [searchResult, setSearchResult] = useState<Array<MyObject>>([])
  const [tables, setTables] = useState<Array<MyObject>>([])
  const [keyListArray, setKeyListArray] = useState<string[][]>([])
  const [processing, setProcessing] = useState<boolean>(false)
  const [preview, setPreview] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>('')
  const [sasUrl, setSasUrl] = React.useState('')
  const [view, setView] = useState<string>('semanticsearch')

  const handleDownload = () => {
    const wb = utils.book_new()

    // Iterate over the values and create a 2D array
    const array2D = Object.entries(tables[0]).map(([key, values]) => [...values])
    const transposeArray = (array: any[][]): any[][] =>
      array[0].map((_, colIndex) => array.map((row) => row[colIndex]))
    const transposedArray = transposeArray(array2D)
    console.log(transposedArray)

    const transaction = utils.aoa_to_sheet([...transposedArray])
    const worksheet = utils.json_to_sheet(selectedCsv)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Customer Docs')
    utils.book_append_sheet(workbook, transaction, 'transaction')
    writeFile(workbook, 'Bank  Report.xlsx')
  }

  const handleProcess = () => {
    // api call
    setView('')
    setProcessing(true)
    console.log(selectedFiles)

    let fileList: Array<Object> = []

    selectedFiles.forEach((selectedFile) => {
      let fileObj = {
        fileLink: selectedFile,
      }
      fileList.push(fileObj)
    })

    setTimeout(() => {
      setView('semanticSearch')
      setSelectedFiles([])
      setProcessing(false)
    }, 5000)
  }

  const handleSelect = (e: any, name: string, url: string, tag: string, processed: number) => {
    if (e.target.checked) {
      selectedFiles.push(name)
      setFileName(name)
      setSasUrl(url)
    }
    if (!e.target.checked) {
      setFileName('')
      setResult([{}])
      const index = selectedFiles.indexOf(name)
      if (index > -1) {
        selectedFiles.splice(index, 1)
      }
    }
  }

  const handlePreview = async () => {
    setPreview(!preview)
  }

  const handleTagSearch = () => {
    setSearchResult([])
    setProcessing(true)
    sharmanSemantic({ search_text: searchQuery }).then((res) => {
      setSearchResult(res.data)
      setProcessing(false)
    })
  }

  const handleViewSelect = (rightView: string) => {
    // setProcessing(true);
    setView(rightView)
    // setResult(dummy);
  }

  // const handleSelectAll = (e: any) => {
  //     if (e.target.checked) {
  //         let tempSlectedFiles = folder?.map(({ filePath }) => filePath);
  //         if (tempSlectedFiles) setSelectedFiles(tempSlectedFiles);
  //     } else {
  //         setSelectedFiles([]);
  //     }
  // };

  // useEffect(() => {
  //     sharmanAllFiles().then((res) => {
  //         setFolder(res.data);
  //     });
  //     console.log(searchResult.length);
  // }, []);

  useEffect(() => {
    filesByUser({ email: currentUser?.email, tag: 'resume' }).then((res) => {
      setFolder(res.data)
    })
  }, [])

  return (
    <div>
      {/* Upload Document */}
      <div className='modal fade' id='kt_modal_assosiate_student' aria-hidden='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Upload Resumes</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <UploadDocuments />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      <div className='card card-xl-stretch mb-xl-8 bg-light' style={{ borderRadius: '0px' }}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 mb-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark mb-2'> Resume Search </span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
                            Search your <strong> files</strong>
                        </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin: Btn Row */}
        <div className='row'>
          <div className='d-flex justify-content-between my-1' data-kt-user-table-toolbar='base'>
            <div className='px-5'>
              <button
                type='button'
                className='btn btn-primary me-3 my-3 w-180px '
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_assosiate_student'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Upload Resumes
              </button>
              {/* <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px "
                                onClick={handlePreview}
                            >
                                {!preview ? "Preview Document" : "Normal Mode"}
                            </button> */}
            </div>
            <div>
              {/* <button
                type='button'
                className='btn btn-primary me-3 my-3 w-200px'
                onClick={handleProcess}
              >
                <KTIcon iconName='gear' className='fs-1' />
                Process
              </button> */}

              {/* <button
                type='button'
                className={`btn btn-primary me-3 my-3 w-200px " ${
                  view !== 'semanticSearch' ? '' : 'disabled'
                }`}
                onClick={(e) => handleViewSelect('semanticSearch')}
              >
                <KTIcon iconName='file' className='fs-1' />
                Semantic Search
              </button> */}

              {/* {Object.keys(result).length === 0 ? null : ( */}
              <button className='btn btn-success me-3 my-3 w-150px' id='kt_drawer_chat_toggle'>
                <KTIcon iconName='message-text-2' className='fs-1' />
                Chat
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* end :: Btn Row */}

        {folder.folderName === '' ? (
          <div className='row'>
            <Blocks
              visible={true}
              height='80'
              width='80'
              ariaLabel='blocks-loading'
              wrapperStyle={{}}
              wrapperClass='blocks-wrapper'
            />
          </div>
        ) : (
          <div className='card-body pt-5'>
            <div className='row d-flex justify-content-between position-relative'>
              {/* Folder UI */}
              <div className='col-sm-12 col-md-3'>
                <div
                  className={`card card-xl-stretch mb-5 mb-xl-8`}
                  style={{ maxHeight: '80vh', overflowY: 'scroll' }}
                >
                  {folder && (
                    <>
                      {/* begin::Header */}
                      <div className='card-header border-0'>
                        <h3 className='card-title fw-bold text-dark'>
                          <KTIcon iconName='folder' className='fs-2x text-primary' />
                          &nbsp;
                          {/* {folder.folderName} */}
                          Resumes
                        </h3>
                      </div>
                      {/* end::Header */}
                      {/* begin::Body */}
                      <div className='card-body pt-2'>
                        {/* begin::Item */}
                        {folder.files.map((file, index) => (
                          <div
                            className='d-flex align-items-center mb-8 mx-5 rounded p-1'
                            style={
                              file.processed == 1
                                ? { backgroundColor: 'lightslategrey' }
                                : { backgroundColor: 'transparent' }
                            }
                          >
                            {/* begin::Bullet */}
                            <span
                              className={`bullet bullet-vertical h-40px ${bgList[index + 1]}`}
                            ></span>
                            {/* end::Bullet */}
                            {/* begin::Checkbox */}
                            <div className='form-check form-check-custom form-check-solid mx-5'>
                              {/* <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                checked={selectedFiles.includes(file.fileUrl)}
                                                                onClick={(e) =>
                                                                    handleSelect(
                                                                        e,
                                                                        file.fileUrl,
                                                                        file.fileOriginalName,
                                                                        file.tag,
                                                                        file.processed
                                                                    )
                                                                }
                                                            /> */}
                            </div>
                            {/* end::Checkbox */}
                            {/* begin::Description */}
                            <div className='flex-grow-1'>
                              <a
                                // href={file.url}
                                // target="_blank"
                                className='text-gray-800 text-hover-primary fw-bold fs-6'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_preview'
                                title={file.fileOriginalName}
                              >
                                {file.fileOriginalName.length > 15
                                  ? file.fileOriginalName.slice(0, 10) + '...'
                                  : file.fileOriginalName}
                              </a>
                              <span className='text-muted fw-semibold d-block'>
                                Created At : {file.createdAt}
                              </span>
                              <span className='badge badge-primary'>{file.tag}</span>
                            </div>
                            {/* end::Description */}
                            {/* <span className="badge badge-light-success fs-8 fw-bold">New</span> */}
                          </div>
                        ))}
                        {/* end:Item */}
                      </div>
                      {/* end::Body */}
                    </>
                  )}
                </div>
              </div>

              {view == '' && (
                <div className='col-sm-12 col-md-9'>
                  <div className='row align-items-center'>
                    {processing && (
                      <Blocks
                        visible={true}
                        height='80'
                        width='80'
                        ariaLabel='blocks-loading'
                        wrapperStyle={{}}
                        wrapperClass='blocks-wrapper'
                      />
                    )}
                  </div>
                </div>
              )}

              {/* semanticSearch */}
              {/* {view == 'semanticSearch' && ( */}
              <div className='col-sm-12 col-md-9'>
                <div
                  className='card card-xl-stretch mb-xl-8 bg-light'
                  style={{ borderRadius: '0px' }}
                >
                  <div className='card-body pt-1'>
                    <div className='d-flex align-items-center justify-content-between position-relative my-1'>
                      <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-md-100 ps-14 border border-dark border-2'
                        placeholder='Search here'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div
                        className='d-flex justify-content-end my-1'
                        data-kt-user-table-toolbar='base'
                      >
                        <button
                          type='button'
                          className='btn btn-primary w-150px mx-2'
                          onClick={handleTagSearch}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    {searchResult.length === 0 ? (
                      <div className='row align-items-center'>
                        {processing && (
                          <Blocks
                            visible={true}
                            height='80'
                            width='80'
                            ariaLabel='blocks-loading'
                            wrapperStyle={{}}
                            wrapperClass='blocks-wrapper'
                          />
                        )}
                      </div>
                    ) : (
                      <div className='card-xl-stretch' style={{ height: '100%' }}>
                        <Semantic searchResult={searchResult} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
