/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { App } from "../App";
import { AuthPage, Logout, useAuth } from "../modules/auth";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import DataSourceDashboard from "../pages/dashboard/DataSourceDashboard";
import DestinationDashboard from "../pages/dashboard/DestinationDashboard";
import { PrivateRoutes } from "./PrivateRoutes";
import { HalDocumentProcessingProxy } from "../pages/layout-builder/HalDocumentProcessingProxy";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const getCookie = (name: string) => {
    const cookieName = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName)) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  };

  const retrievedCookieValue = getCookie('_Secure-Hal');

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="halidp" element={<HalDocumentProcessingProxy />} />
          <Route element={<MasterLayout />}>
            <Route path="dashboard" element={<DashboardWrapper />} />
            <Route path="datasource" element={<DataSourceDashboard />} />
            <Route path="destination" element={<DestinationDashboard />} />
          </Route>
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/*" element={<Navigate to="/auth" />} />
              <Route path="error/*" element={<ErrorsPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };

