import React from 'react';
// import { candidateResumeData10 as candidateResumeData } from './resumeData';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import './template3.css';

export default function Template3({ candidateResumeData }) {

    console.log(candidateResumeData)

    function isKeyEmpty(obj, key) {
        if (!obj.hasOwnProperty(key)) {
            return true;
        }

        const value = obj[key];
        if (typeof value === 'string' && value.trim() === '') {
            return true;
        } else if (Array.isArray(value) && value.length === 0) {
            return true;
        } else if (typeof value === 'object' && Object.keys(value).length === 0) {
            return true;
        }

        return false;
    }

    // Helper function to capitalize and replace underscores with spaces
    function replaceUnderscoresWithSpacesAndCapitalize(inputString) {
        // Check if inputString is undefined or not a string
        if (typeof inputString !== 'string') {
            // Handle the case where inputString is not valid (e.g., return an empty string or throw an error)
            return '';
        }

        // Replace underscores with spaces
        let stringWithSpaces = inputString.replace(/_/g, " ");

        // Capitalize the first letter of each word
        let capitalizedString = stringWithSpaces.replace(
            /\b\w/g,
            function (match) {
                return match.toUpperCase();
            }
        );

        return capitalizedString;
    }

    // Helper function to capitalize 'I', fix spaces after periods and commas, and manage underscores
    function fixSpacesAndUnderscoresAndCapitalizeI(inputString) {

        inputString = inputString.toString()

        // Check if the inputString starts with "http://" or "https://"
        if (
            inputString.startsWith("http://") ||
            inputString.startsWith("https://")
        ) {
            // Extract the link and the text from the inputString
            let link = inputString;
            let str = inputString;

            // Return formatted anchor tag
            return <a href={link} target="_blank">{str}</a>
        }

        // Check if the inputString contains "@"
        if (inputString.includes("@")) {
            return inputString; // Return the string unchanged
        }

        // Split the input string into parts based on non-word characters
        let parts = inputString.split(/(\W+)/);

        // Process each part separately
        let fixedParts = parts.map((part) => {
            // Check if the part contains underscores
            if (part.includes("_")) {
                // Replace underscores with spaces and capitalize first letter of each word
                return part
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (match) => match.toUpperCase());
            } else {
                // Leave other parts unchanged
                return part;
            }
        });

        // Join the fixed parts back together
        let fixedString = fixedParts.join("");

        // Capitalize the first letter of the string
        fixedString =
            fixedString.charAt(0).toUpperCase() + fixedString.slice(1);

        // Remove spaces before periods and commas, if present
        fixedString = fixedString.replace(/\s+([.,])/g, "$1");

        // Add space after periods and commas, if not followed by a space
        fixedString = fixedString.replace(/([.,])(\S)/g, "$1 $2");

        // Capitalize the word "i" when it's lowercase
        fixedString = fixedString.replace(/\bi\b/g, "I");

        return fixedString;
    }

    // Fix time period
    function formatTimePeriod(start, end) {
        if (!end) {
            return start;
        }
        if (!start) {
            return end;
        }

        return `${start}-${end}`;
    }

    function renderValue(value) {

        if (typeof value === "string" || typeof value === "number") {
            return <span className='template-content'>{fixSpacesAndUnderscoresAndCapitalizeI(value)}</span>;
        } else if (Array.isArray(value)) {
            // Check if the array contains only string or number values
            const containsStringOrNumber = value.every(
                (item) => typeof item === "string" || typeof item === "number"
            );

            if (containsStringOrNumber) {
                return (
                    <ul>
                        {value.map((item, index) => (
                            <li key={index}>{renderValue(item)}</li>
                        ))}
                    </ul>
                );
            } else {
                return (
                    <>
                        {value.map((item, index) => (
                            <React.Fragment key={index}>{renderValue(item)}</React.Fragment>
                        ))}
                    </>
                );
            }
        } else if (typeof value === "object") {
            return (
                <ul>
                    {Object.entries(value).map(([key, val], index) => (
                        <li key={index}>
                            <span className='fw-bold template-content'>{replaceUnderscoresWithSpacesAndCapitalize(key)}: </span>
                            {renderValue(val)}
                        </li>
                    ))}
                </ul>
            );
        }
    }

    function renderValueForAdditional(value) {

        if (typeof value === "string" || typeof value === "number") {
            return <span className='template-content'>{fixSpacesAndUnderscoresAndCapitalizeI(value)}</span>;
        } else if (Array.isArray(value)) {
            // Check if the array contains only string or number values
            const containsStringOrNumber = value.every(
                (item) => typeof item === "string" || typeof item === "number"
            );

            if (containsStringOrNumber) {
                return (
                    <ul>
                        {value.map((item, index) => (
                            <li key={index}>{renderValueForAdditional(item)}</li>
                        ))}
                    </ul>
                );
            } else {
                return (
                    <>
                        {value.map((item, index) => (
                            <React.Fragment key={index}>{renderValueForAdditional(item)}</React.Fragment>
                        ))}
                    </>
                );
            }
        } else if (typeof value === "object") {
            return (
                <ul className='mb-2'>
                    {Object.entries(value).map(([key, val], index) => (
                        <li key={index}>
                            <span className='fw-bold template-content'>{replaceUnderscoresWithSpacesAndCapitalize(key)}: </span>
                            {renderValueForAdditional(val)}
                        </li>
                    ))}
                </ul>
            );
        }
    }

    function renderOverviewSection() {
        if (!isKeyEmpty(candidateResumeData, "overview")) {
            return (
                <section className='mb-3'>
                    <div className='row'>
                        <div className='fw-bold text-decoration-underline'>OVERVIEW:</div>
                    </div>
                    {renderValue(candidateResumeData.overview)}
                </section>
            );
        }
        return null;
    }

    function renderProfileSection() {
        if (!isKeyEmpty(candidateResumeData, "summary")) {
            return (
                <section className='mb-3'>
                    <div className='row'>
                        <div className='fw-bold text-decoration-underline'>PROFILE:</div>
                    </div>
                    {renderValue(candidateResumeData.summary)}
                </section>
            );
        }
        return null;
    }

    function renderExperienceSection() {
        if (!isKeyEmpty(candidateResumeData, "work_experience")) {
            return (
                <section className='mb-3'>
                    <div className='row'>
                        <div className='fw-bold text-decoration-underline'>EXPERIENCE:</div>
                    </div>
                    {renderWorkExperience()}
                </section>
            );
        }
        return null;
    }

    function renderWorkExperience() {
        return (
            <>
                {Array.isArray(candidateResumeData.work_experience) ?
                    candidateResumeData.work_experience.map((experience, index) => (
                        <div key={index} className='col-12 mb-2'>
                            <div className='d-flex justify-content-between'>
                                {(!experience.company && !experience.location) ?
                                    <p className='p-0 m-0 fw-bold fst-italic'>
                                        {experience.title}
                                    </p> :
                                    <p className='p-0 m-0 fw-bold'>
                                        {experience.company} {experience.location && `,`} {experience.location}
                                    </p>
                                }
                                <p className='p-0 m-0 fw-bold'>{formatTimePeriod(experience.start_time, experience.end_time)}</p>
                            </div>
                            <p className='p-0 m-0 fw-bold fst-italic'>{!(!experience.company && !experience.location) && experience.title}</p>
                            {renderValue(experience.responsibilities)}
                            {renderValue(experience.remaining_related_data)}
                            {renderAdditionalExperienceData(experience)}
                        </div>
                    )) :
                    <div className='col-12 mb-2'>
                        <div className='d-flex justify-content-between'>
                            <p className='p-0 m-0 fw-bold'>{candidateResumeData.work_experience.company} {candidateResumeData.work_experience.location && `,`} {candidateResumeData.work_experience.location}</p>
                            <p className='p-0 m-0 fw-bold'>{candidateResumeData.work_experience.time_period}</p>
                        </div>
                        <p className='p-0 m-0 fw-bold fst-italic'>{candidateResumeData.work_experience.title}</p>
                        {renderValue(candidateResumeData.work_experience.responsibilities)}
                        {renderValue(candidateResumeData.work_experience.remaining_related_data)}
                        {renderAdditionalExperienceData(candidateResumeData.work_experience)}
                    </div>
                }
            </>
        );
    }

    function renderAdditionalExperienceData(experience) {
        return Object.entries(experience).map(([key, value]) => {
            if (!['title', 'location', 'time_period', 'company', 'responsibilities', 'remaining_related_data', 'start_time', 'end_time'].includes(key)) {
                return (
                    <div key={key}>
                        <p className='p-0 m-0 fw-bold'>{key}: </p>
                        {renderValue(value)}
                    </div>
                );
            }
            return null;
        });
    }

    function renderCertificationsSection() {
        if (!isKeyEmpty(candidateResumeData, "certifications")) {
            const certifications = candidateResumeData.certifications;

            if (Array.isArray(certifications)) {
                if (certifications.some(certification => typeof certification.certification_details === 'string' && certification.certification_details.trim() !== '')) {
                    return (
                        <section className='mb-3'>
                            <div className='row'>
                                <div className='fw-bold text-decoration-underline'>CERTIFICATIONS:</div>
                            </div>
                            <ul>
                                {certifications.map((certification, index) => (
                                    <li key={index}>
                                        <div className=''>{certification.certification_details} {certification.date && `●`} {certification.date}</div>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    );
                }
            } else {
                if (typeof certifications.certification_details === 'string' && certifications.certification_details.trim() !== '') {
                    return (
                        <section className='mb-3'>
                            <div className='row'>
                                <div className='fw-bold text-decoration-underline'>CERTIFICATIONS:</div>
                            </div>
                            <ul>
                                <li>
                                    <div className='p-0 m-0'>{certifications.certification_details} {certifications.date && `●`} {certifications.date}</div>
                                </li>
                            </ul>
                        </section>
                    );
                }
            }
        }
        return null;
    }

    function renderEducationSection() {
        if (!isKeyEmpty(candidateResumeData, "education")) {
            const { education } = candidateResumeData;

            if (Array.isArray(education)) {
                if (education.every(edu => isEmptyEducation(edu))) {
                    return null;
                }

                return (
                    <section className='mb-3'>
                        {renderEducationHeader()}
                        {education.map((edu, index) => (
                            <div key={index}>
                                {renderEducationDetails(edu)}
                            </div>
                        ))}
                    </section>
                );
            } else if (typeof education === 'object') {
                const { university, location, start_time, end_time, degree, remaining_related_data, ...additionalData } = education;
                if (isEmptyEducation({ university, location, start_time, end_time, degree, remaining_related_data, ...additionalData })) {
                    return null;
                }

                return (
                    <section className='mb-3'>
                        {renderEducationHeader()}
                        <div>
                            {renderEducationDetails({ university, location, start_time, end_time, degree, remaining_related_data, ...additionalData })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    function renderEducationHeader() {
        return (
            <div className='row'>
                <div className='fw-bold text-decoration-underline'>EDUCATION:</div>
            </div>
        );
    }

    function renderEducationDetails(edu) {
        const { university, location, start_time, end_time, degree, remaining_related_data, ...additionalData } = edu;

        return (
            <div className='mb-2'>
                <div className='fw-bold'>{university} {location && `●`} {location} {(start_time || end_time) && `●`} {formatTimePeriod(start_time, end_time)}</div>
                <div className='fw-bold fst-italic'>{degree}</div>
                {renderValue(remaining_related_data)}
                {renderAdditionalEducationData(additionalData)}
            </div>
        );
    }

    function isEmptyEducation(edu) {
        const { university, location, time_period, degree, remaining_related_data, ...additionalData } = edu;
        const additionalDataValues = Object.values(additionalData);
        if ((typeof university === 'string' && university.trim() === '') &&
            (typeof location === 'string' && location.trim() === '') &&
            (typeof degree === 'string' && degree.trim() === '') &&
            (typeof time_period === 'string' && time_period.trim() === '') &&
            (typeof remaining_related_data === 'string' && remaining_related_data.trim() === '') &&
            additionalDataValues.every(value => typeof value === 'string' && value.trim() === '')) {
            return true;
        }
        return false;
    }

    function renderAdditionalEducationData(edu) {
        return Object.entries(edu).map(([key, value]) => {
            if (!['university', 'location', 'start_time', 'end_time', 'degree', 'remaining_related_data'].includes(key)) {
                return (
                    <div key={key}>
                        <div className='fw-bold'>{key}: </div>
                        {renderValue(value)}
                    </div>
                );
            }
            return null;
        });
    }

    function renderSkillsSection() {
        if (!isKeyEmpty(candidateResumeData, "skills")) {
            const { skills } = candidateResumeData;

            if (Array.isArray(skills)) {
                if (skills.every(skill => isEmptySkill(skill))) {
                    return null;
                }

                return (
                    <section className='mb-3'>
                        {renderSkillsHeader()}
                        {skills.map((skill, index) => (
                            <div key={index}>
                                {renderSkill(skill)}
                            </div>
                        ))}
                    </section>
                );
            } else if (typeof skills === 'object') {
                const { heading, related_data } = skills;

                if (isEmptySkill({ heading, related_data })) {
                    return null;
                }

                return (
                    <section className='mb-3'>
                        {renderSkillsHeader()}
                        <div>
                            {renderSkill({ heading, related_data })}
                        </div>
                    </section>
                );
            }
        }
        return null;
    }

    function renderSkillsHeader() {
        return (
            <div className='row'>
                <div className='fw-bold text-decoration-underline'>SKILLS:</div>
            </div>
        );
    }

    function renderSkill(skill) {
        const { heading, related_data } = skill;
        if (related_data === '') {
            return (
                <ul>
                    <li>
                        <span className="fw-bold">{renderValue(heading)}</span>
                    </li>
                </ul>
            )
        } else if (heading === "" || heading.toString().toLowerCase() === "skills" || heading.toString().toLowerCase() === "skill") {
            return renderValue(related_data);
        } else {
            return (
                <ul>
                    <li>
                        <span className="fw-bold">{renderValue(heading)}</span>
                        {Array.isArray(related_data) ? renderValue(related_data) : <ul>{renderValue(related_data)}</ul>}
                    </li>
                </ul>
            );
        }
    }

    function isEmptySkill(skill) {
        const { heading, related_data } = skill;
        if ((typeof heading === 'string' && heading.trim() === '') && (typeof related_data === 'string' && related_data.trim() === '')) {
            return true;
        }
        return false;
    }

    // Function to render projects section
    const renderProjectsSection = () => {
        if (!isKeyEmpty(candidateResumeData, "projects")) {
            const { projects } = candidateResumeData;

            if (Array.isArray(projects)) {
                if (projects.every((proj) => isEmptyProject(proj))) {
                    return null; // Handle case where all projects are empty
                }

                const projectsHTML = projects.map(renderProjectDetails);
                return (
                    <section className='mb-3'>
                        {renderProjectsHeader()}
                        {projectsHTML}
                    </section>
                );
            } else if (typeof projects === "object") {
                if (isEmptyProject(projects)) {
                    return null; // Handle case where project is empty
                }

                return (
                    <section className='mb-3'>
                        {renderProjectsHeader()}
                        <div>{renderProjectDetails(projects)}</div>
                    </section>
                );
            }
        }

        return null; // Handle case where no projects exist or projects key is empty
    };

    // Function to render projects header
    const renderProjectsHeader = () => {
        return (
            <div className='row'>
                <div className='fw-bold text-decoration-underline'>PROJECTS:</div>
            </div>
        );
    };

    // Function to render each project item
    const renderProjectDetails = (proj) => {
        const {
            project_name,
            technologies,
            link,
            time_period,
            remaining_related_data,
        } = proj;

        return (
            <div className="mb-2">
                <div className='fw-bold'>
                    {replaceUnderscoresWithSpacesAndCapitalize(project_name)}
                    {time_period ? ` ● ${time_period}` : ""}
                </div>
                {technologies && <div><span className='fw-bold'>Technologies:</span> {technologies}</div>}
                {link && <a href={link} target='_blank' rel='noopener noreferrer'>Project Link</a>}
                {renderValue(remaining_related_data)}
            </div>
        );
    };

    // Function to check if a project item is empty
    const isEmptyProject = (proj) => {
        const {
            project_name,
            technologies,
            link,
            time_period,
            remaining_related_data,
        } = proj;

        const additionalDataValues = remaining_related_data ? Object.values(remaining_related_data) : Object.values({});
        if (
            typeof project_name === "string" && project_name.trim() === "" &&
            typeof technologies === "string" && technologies.trim() === "" &&
            typeof link === "string" && link.trim() === "" &&
            typeof time_period === "string" && time_period.trim() === "" &&
            additionalDataValues.every((value) => typeof value === "string" && value.trim() === "")
        ) {
            return true;
        }
        return false;
    };

    function renderAcademicAchievementsSection() {
        if (!isKeyEmpty(candidateResumeData, "academic_achievements")) {
            return (
                <section className='mb-3'>
                    <div className='row'>
                        <div className='fw-bold text-decoration-underline'>ACADEMIC ACHIEVEMENTS:</div>
                    </div>
                    {renderValueForAdditional(candidateResumeData.academic_achievements)}
                </section>
            );
        }
        return null;
    }

    function renderAdditionalSection() {
        if (!isKeyEmpty(candidateResumeData, "additional")) {
            return (
                <section>
                    <div className='row'>
                        <div className='fw-bold text-decoration-underline'>ADDITIONAL:</div>
                    </div>
                    {renderValueForAdditional(candidateResumeData.additional)}
                </section>
            );
        }
        return null;
    }

    return (
        <>
            <div className='template-body-2'>
                <div className='d-flex justify-content-between'>
                    <p className='template-name'>{candidateResumeData.name}</p>
                    <img src={toAbsoluteUrl("/media/logos/forum_logo.png")} alt="Forum logo" height={45} />
                </div>

                {renderOverviewSection()}
                {renderProfileSection()}
                {renderCertificationsSection()}
                {renderEducationSection()}
                {renderExperienceSection()}
                {renderSkillsSection()}
                {renderProjectsSection()}
                {renderAcademicAchievementsSection()}
                {renderAdditionalSection()}
            </div>
        </>
    );
}
