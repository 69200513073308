import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { HalLogin } from "./components/HalLogin";
import { Login } from "./components/Login";
import { Registration } from "./components/Registration";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/sketchy-1/14.png"
        )})`,
        backgroundColor: "#1e3c4a"
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo and Text */}
        <a href="https://optira.ai/" target="_blank">
          <div className="d-flex align-items-start mb-2">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/default.png")}
              className="h-65px"
            />
            <div className="d-flex flex-column ml-3">
              <span className="fw-bolder fs-1 text-primary">Optira</span>
              <span className="fs-6  text-white">AI-based Document Processing & Analytics Platform</span>
            </div>
          </div>
        </a>
        {/* end::Logo and Text */}

        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>


      {/* end::Content */}
      {/* begin::Footer */}
      <div className="p-10">
        <div className="row">
          <div className="col-4">
            <div className="">
              <div className="d-flex fw-bold fs-4 align-items-start my-2">
                <span>A Real-world AI Product by
                  <a
                    href="https://thirdeyedata.ai/"
                    target="_blank"
                    className="text-hover-primary px-2"
                    style={{ color: "#fff", textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    <img src={toAbsoluteUrl("/media/logos/thirdeyelogo-horizontal.png")} alt="Thirdeye data" height={25} className="mb-3" /> &nbsp;
                  </a>
                </span>
              </div>
              <span className="fw-light fs-7">All Rights Reserved © 2024 - ThirdEye Data (USA)</span>
            </div>
          </div>
          <div className="col-4 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center fw-bold fs-2">
              <a
                href="https://optira.ai/"
                target="_blank"
                className="text-hover-primary px-2"
                style={{ color: "#fff" }}
              >
                Sales
              </a>
              |
              <a
                href="https://optira.ai/submit-ticket/"
                target="_blank"
                className="text-hover-primary px-2"
                style={{ color: "#fff" }}
              >
                Support
              </a>
            </div>
          </div>
          <div className="col-4">

          </div>


        </div>

      </div>


      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="hallogin" element={<HalLogin />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
