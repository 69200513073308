import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth';

type ButtonRowProps = {
    preview: string;
    loading: boolean;
    currentUserEmail: string | undefined;
    fileLink: string | undefined; // Add fileLink to props type
    toPDF: () => void;
    handlePreview: () => void;
    handleResumeProcess: (fileLink: string) => void;
    startContinousProcess: () => void;
};

const ButtonRow: React.FC<ButtonRowProps> = ({
    preview,
    loading,
    currentUserEmail,
    fileLink, // Declare fileLink here
    toPDF,
    handlePreview,
    handleResumeProcess,
    startContinousProcess,
}) => {

    const { currentUser } = useAuth()

    return (
        <div className="row">
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5">
                    {preview == "table" && (
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_assosiate_student"
                        >
                            {!loading ? (
                                <span className="indicator-label">
                                    <KTIcon iconName="file-up" className="fs-3" /> Upload Resume
                                </span>
                            ) : (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Uploading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    )}
                    {(preview == "compare" || preview == "download") && (
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px"
                            onClick={handlePreview}
                        >
                            Back to Resumes Library View
                        </button>
                    )}
                </div>
                <div>
                    {(preview == "compare" || preview == "download") && (
                        <>
                            {currentUserEmail === 'vp@thirdeyedata.ai' && fileLink && (
                                <button
                                    type="button"
                                    className="btn btn-primary me-3 my-3 w-200px"
                                    data-bs-toggle="modal"
                                    data-bs-target={
                                        currentUserEmail === 'vp@thirdeyedata.ai' || currentUserEmail === 'forum@thirdeyedata.ai'
                                            ? ''
                                            : '#kt_modal_select_resume'
                                    }
                                    onClick={() => currentUserEmail === 'vp@thirdeyedata.ai' && handleResumeProcess(fileLink)}
                                >
                                    <KTIcon iconName="pencil" className="fs-1" />
                                    Format
                                </button>
                            )}
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => toPDF()}>
                                <KTIcon iconName="cloud-download" className="fs-1" />
                                Download PDF
                            </button>
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => { }}>
                                <KTIcon iconName="document" className="fs-1" />
                                Print
                            </button>
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => { }}>
                                <KTIcon iconName="sms" className="fs-1" />
                                Email
                            </button>
                        </>
                    )}
                    {/* <button type="button" className="btn btn-primary me-3 my-3 w-200px" onClick={startContinousProcess}>
                        <KTIcon iconName="gear" className="fs-1" />
                        Process Now
                    </button> */}
                    {/* <button type="button" className="btn btn-primary me-3 my-3 w-200px" data-bs-toggle="modal" data-bs-target="#kt_modal_schedule">
                        <KTIcon iconName="time" className="fs-1" />
                        Schedule Process
                    </button> */}
                    {
                        currentUser?.usertype == 'superadmin' &&
                        <Link to="/statistics/resume">
                            <button type="button" className="btn btn-primary me-3 my-3 w-200px">
                                <KTIcon iconName="graph-2" className="fs-1" />
                                Analytics
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </div >
    );
};

export default ButtonRow;
