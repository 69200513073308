/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { KTIcon } from '../../../helpers'
import { ChatInner } from '../../chat/ChatInner'
import { resetContest } from '../../../../app/api'
import { useAuth } from '../../../../app/modules/auth'

const DrawerMessenger: FC = () => {

  const { currentUser } = useAuth()
  const [maximize, setMaximize] = useState(false)
  const [dataSource, setDataSource] = useState('idp')


  return (

    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      // data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card rounded-0' id='kt_drawer_chat_messenger' style={maximize ? { width: "800px" } : { width: "400px" }}>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                Optira's Custom ChatGPT
              </a>

              <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='me-2'>

              <button className='btn btn-sm'>
                <select
                  className="form-select form-select-solid fw-bolder"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  data-kt-user-table-filter="role"
                  data-hide-search="true"
                  value={dataSource ? dataSource : ''}
                  onChange={(e) => {
                    setDataSource(e.target.value);
                  }}
                >
                  <option value="">-- Select GPT Type --</option>
                    {(!currentUser || currentUser?.resume_search_service == 1) &&  <option value="resumesearch">GPT for Resume Search</option>}
                    {(!currentUser || currentUser?.chatgpt_service == 1 || currentUser?.document_service == 1) && <option value="idp">GPT for IDP</option>}
                </select>
              </button>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                title='Clear Context'
                onClick={(e) => { resetContest({ dataSource: dataSource ? dataSource : '' }).then((res) => { alert(res.data) }) }}
              >
                <KTIcon iconName='eraser' className='fs-2' />
              </button>
              {/* Filter Data Source */}
              {/* <button
                type="button"
                className='btn btn-sm btn-icon btn-active-light-primary mx-2'
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >

                <KTIcon iconName='filter' className='fs-2' />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                data-kt-menu="true"
              >
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bolder">Filter Data Source</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className="px-7 py-5" data-kt-user-table-filter="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Data Source:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      value={dataSource ? dataSource : ''}
                      onChange={(e) => {
                        setDataSource(e.target.value);
                      }}
                    >
                      <option value="">-- Select File Type --</option>
                      <option value="snowflake">Snowflake</option>
                      <option value="sharepoint">Azure Blob</option>
                      <option value="combined">Combined</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success fw-bold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div> */}
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                onClick={(e) => setMaximize(!maximize)}
              // data-kt-menu-trigger='click'
              // data-kt-menu-placement='bottom-end'
              // data-kt-menu-flip='top-end'
              >
                {
                  !maximize ?
                    <KTIcon iconName='arrow-two-diagonals' className='fs-2' /> :
                    <KTIcon iconName='exit-right' className='fs-2' />

                }
              </button>
            </div>

            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <ChatInner isDrawer={true} datasource={dataSource ? dataSource : ''} />
      </div>
    </div>
  )
}

export { DrawerMessenger }
