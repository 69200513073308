import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { useAuth } from "../modules/auth";

// Importing specific pages/components
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import DocumentProcessingDashboard from "../pages/dashboard/DocumentProcessingDashboard";
import DocumentTypes from "../pages/dashboard/DocumentTypes";
import Statistics from "../pages/dashboard/Statistics";
import AudioProcessing from "../pages/layout-builder/AudioProcessing";
import CustomConversation from "../pages/layout-builder/CustomConversation";
import DocumentProcessingNew from "../pages/layout-builder/DocumentProcessingNew";
import { DriveAnalytics } from "../pages/layout-builder/DriveAnalytics";
import MetaTagExtraction from "../pages/layout-builder/MetaTagExtraction";
import ResumeFormat from "../pages/layout-builder/ResumeFormat";
import SampleQuestion from "../pages/layout-builder/SampleQuestion";
import SemanticSearch from "../pages/layout-builder/SemanticSearch";
import Summarization from "../pages/layout-builder/Summarization";
import { Teams } from "../pages/layout-builder/Teams";
import TextExtraction from "../pages/layout-builder/TextExtraction";
import WebsiteCategorization from "../pages/layout-builder/WebsiteCategorization";

// PrivateRoutes component definition
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"));

  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after successful login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="/*" element={<Navigate to="/dashboard" />} />

        {/* Specific pages/routes */}
        <Route path="documentprocessing/:doctype" element={<DocumentProcessingNew />} />
        <Route path="websitecategorization" element={<WebsiteCategorization />} />

        {/* Conditional rendering based on user permissions */}
        {currentUser?.document_service && <Route path="documentsdashboard" element={<DocumentProcessingDashboard />} />}
        {currentUser?.audio_service && <Route path="audioprocessing" element={<AudioProcessing />} />}
        {currentUser?.resume_service && <Route path="resumeformater" element={<ResumeFormat />} />}
        {currentUser?.resume_search_service && <Route path="resumesearch" element={<SemanticSearch />} />}
        {currentUser?.chatgpt_service && <Route path="customconversation" element={<CustomConversation />} />}

        {/* Other static pages */}
        <Route path="features" element={<DashboardWrapper />} />
        <Route path="doctypes" element={<DocumentTypes />} />
        <Route path="statistics/:doctype" element={<Statistics className="Statistics" />} />
        <Route path="tagextraction" element={<MetaTagExtraction />} />
        <Route path="documenttextextraction" element={<TextExtraction />} />
        <Route path="summarization" element={<Summarization />} />
        <Route path="samplequestion" element={<SampleQuestion />} />
        <Route path="myteam" element={<Teams />} />
        <Route path="driveanalytics" element={<DriveAnalytics />} />

        {/* Lazy-loaded modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView><ProfilePage /></SuspensedView>} />
        <Route path="crafted/pages/wizards/*" element={<SuspensedView><WizardsPage /></SuspensedView>} />
        <Route path="crafted/widgets/*" element={<SuspensedView><WidgetsPage /></SuspensedView>} />
        <Route path="crafted/account/*" element={<SuspensedView><AccountPage /></SuspensedView>} />
        <Route path="customchatgpt/*" element={<SuspensedView><ChatPage /></SuspensedView>} />
        <Route path="apps/user-management/*" element={<SuspensedView><UsersPage /></SuspensedView>} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

// SuspensedView component definition
const SuspensedView: FC<WithChildren> = ({ children }) => {
  // Get base color for progress bar
  const baseColor = getCSSVariableValue("--bs-primary");

  // Configure TopBarProgress settings
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });

  // Render Suspense with fallback progress indicator
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

// Export PrivateRoutes component
export { PrivateRoutes };

