import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { FilePreviewUIProps } from './DocumentProcessingModel';
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export const FilePreviewUI: React.FC<FilePreviewUIProps> = ({
    transcribe,
    audioFile,
    fileName,
    doctype,
}) => {
    return (
        <div className={`col-sm-12 col-md-6`}>
            <div
                className={`card card-xl-stretch mb-5 mb-xl-8`}
                style={{ minHeight: '70vh', overflowY: 'auto' }}
            >
                <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">
                        <KTIcon
                            iconName="screen"
                            className="fs-2x text-primary"
                        />
                        &nbsp; Preview Audio
                    </h3>
                </div>
                <div className='card-body'>
                    {/* Render Audio file preview */}
                    <ReactAudioPlayer
                        style={{ borderRadius: '10px', width: '100%' }}
                        src={audioFile}
                        autoPlay
                        controls
                    />
                    {/* <AudioPlayer
                        style={{ borderRadius: '10px' }}
                        autoPlay
                        src={audioFile}
                        onPlay={e => console.log("onPlay")}
                    /> */}
                    <div className='card bg-light mt-4 p-5' style={{ height: '40vh', overflowY: 'auto' }}>
                        <div className='d-flex align-items-center'>
                            <KTIcon
                                iconName="text-align-justify-center"
                                className="fs-3 text-primary"
                            /> &nbsp;
                            <p className='fs-3 fw-bold mb-0'>
                                Transcription
                            </p>
                        </div>
                        <hr />
                        <p>
                            {transcribe}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};