/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { FeatureCard } from '../../modules/dashboard/FeatureCard'

type Props = {
  countUniversity: number
  countAssociatedLicences: number
  countLicences: number
}

const DashboardPage = ({ countUniversity, countAssociatedLicences, countLicences }: Props) => (
  <div></div>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const [superAdminStats, setSuperAdminStats] = useState({
    countUniversity: 0,
    countLicences: 0,
    countAssociatedLicences: 0,
  })

  return (
    <div className='container-xxl'>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <div className='card-header border-0 pt-6'>
            <div className='card-title'></div>
          </div>
          {/* begin::Row */}
          <div className='row g-5 g-xl-8'>
            {(!currentUser || currentUser?.document_service == 1) && (
              <div className='col-xl-4'>
                <FeatureCard
                  className='card-xl-stretch mb-xl-8'
                  tag={
                    !currentUser
                      ? '/auth'
                      : currentUser.username == 'HAL'
                        ? '/documentprocessing'
                        : '/documentsdashboard'
                  }
                  svgIcon='abstract-26'
                  color='light'
                  iconColor='dark'
                  title='Intelligent Document Processing'
                  titleColor='dark'
                  description='Optira harnesses the power of Gen AI, overcoming the limitations of traditional OCR technologies, 
                              to automate data processing over all such documents.'
                  descriptionColor='dark'
                />
              </div>
            )}

            {(!currentUser || currentUser?.audio_service == 1) && (
              <div className='col-xl-4'>
                <FeatureCard
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='message-text-2'
                  tag={!currentUser ? '/auth' : '/audioprocessing'}
                  color='light'
                  iconColor='dark'
                  title='Intelligent Audio Processing'
                  titleColor='dark'
                  description='You can develop an audio processing solution to extract details from calls into a key-value pair format, 
                              enabling efficient analysis and automation of call data.'
                  descriptionColor='dark'
                />
              </div>
            )}

            {(!currentUser || currentUser?.resume_service == 1) && (
              <div className='col-xl-4'>
                <FeatureCard
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='some-files'
                  tag={!currentUser ? '/auth' : '/resumeformater'}
                  color='light'
                  iconColor='dark'
                  title='Resume Format Conversions'
                  titleColor='dark'
                  description='Optira deploys a versatile document format conversion functionality that allows for seamless transformation 
                              of documents into different formats.'
                  descriptionColor='dark'
                />
              </div>
            )}

            {(!currentUser || currentUser?.resume_search_service == 1) && (
              <div className='col-xl-4'>
                <FeatureCard
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='filter-search'
                  tag={!currentUser ? '/auth' : '/resumesearch'}
                  color='light'
                  iconColor='dark'
                  title='Resume Searching Engine'
                  titleColor='dark'
                  description='Optira leverages Advanced AI-powered tool for efficiently matching resumes with job requirements based on 
                              contextual understanding and skills analysis.'
                  descriptionColor='dark'
                />
              </div>
            )}

            {(!currentUser || currentUser?.chatgpt_service == 1) && (
              <div className='col-xl-4'>
                <FeatureCard
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='message-text-2'
                  tag={!currentUser ? '/auth' : '/customconversation'}
                  color='light'
                  iconColor='dark'
                  title='Custom ChatGPT Conversations'
                  titleColor='dark'
                  description='Optira provides Tailored conversational experiences with ChatGPT, designed to meet unique needs and 
                              preferences based on your dataset.'
                  descriptionColor='dark'
                />
              </div>
            )}

          </div>
          {/* end::Row */}
        </div>
      </div>
    </div>
  )
}

export { DashboardWrapper }

