import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderSectionProps {
  doctype: string;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({ doctype }) => {
  return (
    <div className="card-header border-0 pt-5 mb-5 align-items-center">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold text-dark mb-2">
          {/* {doctype !== "bankstatement" ? doctype.charAt(0).toUpperCase() + doctype.slice(1) + "s Library" : "Bank Statements Library"} */}
          Audio Library
        </span>
      </h3>
      <span>
        {/* <a href={'https://drive.google.com/drive/folders/1s20nRLKR0AmdtTa0q4zUKq0OfCU_el0c?usp=drive_link'} target="_blank">Sample Document</a> | <Link to={'/samplequestion'}>Sample Question</Link> */}
      </span>
    </div>
  );
};
