import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef, useState } from 'react'
import { Blocks } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { KTIcon } from '../../../_metronic/helpers'
import { useThemeMode } from '../../../_metronic/partials'
import { datestatistic, gdriveCount, gdriveTrack, memberStatistic } from '../../api'
import { useAuth } from '../../modules/auth'
import { MixedWidgetPieChart } from '../../../_metronic/partials/widgets/mixed/MixedWidgetPieChart'
import { MixedWidget10, MixedWidget11 } from '../../../_metronic/partials/widgets'
import { TeamMemberGraph } from '../../modules/statistics/TeamMemberGraph'
import { MemberData } from '../../modules/statistics/StatisticsModel'

type Props = {
    className: string;

}

type MyObject = {
    data: {
        processed_count: number;
        unprocessed_count: number;
        total_count: number;
        average_time: number;
        accepted_count: number;
        rejected_count: number;
        pending_count: number;
        download_count: number;
    };
};

type DailyCount = {
    date: string;
    count: number;
}

const Statistics: React.FC<Props> = ({ className }) => {

    const { doctype } = useParams()

    const chartRef = useRef<HTMLDivElement | null>(null)
    const { currentUser } = useAuth();
    const [tag, setTag] = useState<string>(doctype ? doctype : "invoice")
    const [maxDate, setMaxDate] = useState<string>()
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 10)))
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateList, setDateList] = useState<string[]>()
    const [countList, setCountList] = useState<number[]>()
    const [processCountList, setProccessCountList] = useState<number[]>()
    const [unprocessCountList, setUnprocessCountList] = useState<number[]>()
    const [processing, setProcessing] = useState<boolean>(false)
    const [interval, setInterval] = useState<string>()
    const [membersProgressAnalytics, setMembersProgressAnalytics] = useState<MemberData[]>()
    const [statisticsData, setStatisticsData] = useState<MyObject>({
        data: {
            processed_count: 0,
            unprocessed_count: 0,
            total_count: 0,
            average_time: 0,
            accepted_count: 0,
            rejected_count: 0,
            pending_count: 0,
            download_count: 0,
        }
    });

    const { mode } = useThemeMode()

    const convertDateFormat = (dt: Date) => {
        const currentDate = dt

        // Format the updated date as YYYY-MM-DD
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    useEffect(() => {
        const chart = refreshChart()
        console.log("charting")

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, dateList, processCountList, unprocessCountList])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        if (dateList && dateList?.length > 0 && countList && countList?.length > 0 && processCountList && processCountList?.length > 0 && unprocessCountList && unprocessCountList?.length > 0) {
            const chart = new ApexCharts(
                chartRef.current,
                getChartOptions(
                    height,
                    countList,
                    dateList,
                    processCountList,
                    unprocessCountList,
                )
            )
            if (chart) {
                chart.render();
            }
            return chart
        }

        return
    }

    const selectedDate = (dt: Date, type: string) => {
        if (dt && type == "start") {
            setStartDate(dt)
        } else if (dt && type == "end") {
            setEndDate(dt)
        }
    }



    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setMaxDate(formattedDate);
    }, [])

    useEffect(() => {
        if (tag && startDate && endDate) {
            setProcessing(true)
            console.log(startDate, endDate)
            let stDt = convertDateFormat(startDate)
            let edDt = convertDateFormat(endDate)
            datestatistic({ useremail: currentUser?.email, tag: tag, created_at: [stDt, edDt], })
                .then((res) => {
                    console.log(res)
                    if (res) {
                        setStatisticsData({
                            data: {
                                processed_count: Number(res?.processed_count) || 0,
                                unprocessed_count: Number(res?.unprocessed_count) || 0,
                                total_count: Number(res?.total_count) || 0,
                                average_time: Number(res?.average_time) || 0,
                                accepted_count: Number(res?.accepted_count) || 0,
                                rejected_count: Number(res?.rejected_count) || 0,
                                pending_count: Number(res?.pending_count) || 0,
                                download_count: Number(res?.download_count) || 0,
                            }
                        });
                        // Assuming res?.dailyCounts is of type DailyCount[]
                        const dates: string[] = res?.daily_count.date;
                        const counts: number[] = res?.daily_count.total_count;
                        const processCounts: number[] = res?.daily_count.processed_count;
                        const unprocessCounts: number[] = res?.daily_count.unprocessed_count;

                        setDateList(dates)
                        setCountList(counts)
                        setProccessCountList(processCounts)
                        setUnprocessCountList(unprocessCounts)
                        // refreshChart()
                        setProcessing(false)
                    } else {
                        setStatisticsData({
                            data: {
                                processed_count: 0,
                                unprocessed_count: 0,
                                total_count: 0,
                                average_time: 0,
                                accepted_count: 0,
                                rejected_count: 0,
                                pending_count: 0,
                                download_count: 0,
                            }
                        });
                        setProcessing(false)
                    }
                })
                .catch((error) => {
                    setProcessing(false)
                    console.error('Error fetching data:', error);
                    // Handle API call error if necessary
                });
        }
    }, [tag, startDate, endDate]);

    useEffect(() => {
        gdriveCount({ "folder_ids": ["1SS5VhUrE7T--V1wxXsRdUofLQGDODbsJ"] }).then((res: any) => {
            console.log(res)
        })
        gdriveTrack({ "folder_id": "1SS5VhUrE7T--V1wxXsRdUofLQGDODbsJ" }).then((res: any) => {
            console.log(res)
        })
        memberStatistic({ "org_id": currentUser?.organizationId, tag: 'resume' }).then((res: any) => {
            setMembersProgressAnalytics(res.data)
        })
    }, [])


    return (
        <div className="card card-xl-stretch mb-xl-8 bg-light px-20" style={{ borderRadius: '0px', minHeight: '90vh' }}>
            <h1 style={{ marginTop: '20px' }}>Analytics Dashboard</h1>
            <div style={{ borderRadius: '0px', minHeight: '90vh' }}>
                <div className='row mt-4'>
                    <div className='col-md-4 d-flex align-items-center'>
                        <select className='form-select' style={{ width: '250px' }} value={tag} onChange={(e) => { setTag(e.target.value) }}>
                            <option selected>Select Document Type</option>
                            {currentUser?.document_service == 1 &&
                                <>
                                    <option value='invoice'>Invoice</option>
                                    <option value='bankstatement'>Bank Statement</option>
                                    <option value='handwritten'>Handwritten</option>
                                    <option value='fax'>Fax</option>
                                    <option value='vouchers'>Vouchers</option>
                                    <option value='receipt'>Reciept</option>
                                </>
                            }
                            {currentUser?.resume_service == 1 && <option value='resume'>Resume</option>}
                        </select>
                    </div>
                    <div className='col-md-4 d-flex align-items-center justify-content-end'>
                        <div className='input-group justify-content-end' style={{ marginRight: '10px' }}>
                            <input
                                type='date'
                                min="2023-01-01"
                                max={maxDate}
                                value={startDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    selectedDate(new Date(e.target.value), "start"); // Assuming selectedDate is a function
                                }}
                                className='form-control'
                                style={{ width: '80px', padding: '0.375rem 0.75rem' }}
                            />
                            <span className='input-group-text'>to</span>
                            <input
                                type='date'
                                className='form-control'
                                min="2023-01-01"
                                max={maxDate}
                                value={endDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    selectedDate(new Date(e.target.value), "end");
                                }}
                                style={{
                                    width: '80px',
                                    padding: '0.375rem 0.75rem',
                                }}
                            />
                        </div>
                    </div>

                    {/* <div className='col-md-4 d-flex align-items-center justify-content-end'>
                        <button className='btn btn-primary'>
                            <i className='bi bi-download'></i> Download Report
                        </button>
                    </div> */}
                </div>
                <div className='row mt-4'>
                    {
                        processing ?
                            <Blocks
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                            :
                            <>
                                {/* Four additional boxes above the existing ones */}
                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="file-up" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Uploaded
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.total_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 4 content goes here */}
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="filter-tick" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Processed <br /> Document
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.processed_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="information" className="fs-1" />
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Unprocessed <br /> Document
                                                        </a>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.unprocessed_count}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 5 content goes here */}
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="watch" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >
                                                            Average <br /> Process Time
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{(statisticsData.data.average_time).toFixed(2)} <span className='fs-6'>min</span></div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="shield-tick" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Accepted
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.accepted_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="shield-cross" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Rejected
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.rejected_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="information" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Pending Approval
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.total_count - (statisticsData.data.accepted_count + statisticsData.data.rejected_count)}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="file-down" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Downloaded
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{tag == "resume" ? statisticsData.data.download_count : "N/A"}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='card' style={{ marginBottom: '20px' }}>
                                        <div className='card-body'>
                                            <h5 className='card-title'>Power BI Dashboard</h5>
                                            <iframe
                                                title="bosch_invoice_insight"
                                                width="1300"
                                                height="700"
                                                src="https://app.powerbi.com/view?r=eyJrIjoiODA0N2YxNDktM2FmNi00OTI0LTkwOGQtNDhjMTIyM2MyNTQ1IiwidCI6IjNkN2EzZjkwLTFkMmMtNGQ5MS05YjQ5LTUyZTA5OGNmOWViOCIsImMiOjZ9"
                                                frameBorder="0"
                                                allowFullScreen={true}
                                            >
                                            </iframe>
                                        </div>
                                    </div>
                                </div>

                                {/* Team Member Analytics */}
                                {/* <div className={`col-md-12 card ${className}`} style={{ marginBottom: '20px' }}>
                                    <div className='card' style={{ marginBottom: '20px' }}>
                                        <div className='card-body'>
                                            <h5 className='card-title'>Team Member Analytics</h5>
                                            {
                                                (membersProgressAnalytics && membersProgressAnalytics.length > 0) &&
                                                <TeamMemberGraph
                                                    // heading="Text Classifier"
                                                    className="card-xl-stretch mb-xl-8"
                                                    chartColor="success"
                                                    chartHeight="250px"
                                                    graphData={membersProgressAnalytics}
                                                />}
                                        </div>
                                    </div>
                                </div> */}

                                {/* Chart Section */}
                                <div className={`col-md-12 card ${className}`} style={{ marginBottom: '20px', height: '400px' }}>
                                    <div className='card-body'>
                                        <h5 className='card-title'>Datewise File Analytics</h5>
                                        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '300px' }} />
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}
export default Statistics;



function getChartOptions(height: number, countList: number[], dateList: string[], processCountList: number[], unprocessCountList: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const baseColor = getCSSVariableValue('--bs-primary');
    const secondBaseColor = getCSSVariableValue('--bs-success');
    const secondaryColor = getCSSVariableValue('--bs-warning');
    console.log(dateList, countList, processCountList, unprocessCountList)
    return {
        series: [
            {
                name: 'Uploads',
                data: countList,
            },
            {
                name: 'Proccessed',
                data: processCountList,
            },
            {
                name: 'Unprocessed',
                data: unprocessCountList,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // Adjust the columnWidth to decrease the width of the bars
                columnWidth: '35%', // Adjust this value as needed
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: dateList,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' documents';
                },
            },
        },
        colors: [baseColor, secondBaseColor, secondaryColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    };

}