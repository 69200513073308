import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface HeaderSectionProps {
  doctype: string;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({ doctype }) => {
  const [currentDoctype, setCurrentDoctype] = useState(doctype); // State for current selected doctype
  const navigate = useNavigate()

  const handleDropdownClick = (type: string) => {
    setCurrentDoctype(type); // Update current selected doctype
  };

  return (
    <div className="card-header border-0 pt-5 mb-5 align-items-center">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bold text-dark mb-2">
          <Link to={"/documentsdashboard"}>
            Documents Types
          </Link>
          {" / "}
          <span className="dropdown">
            <button className="btn btn-light dropdown-toggle text-dark" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {currentDoctype !== "bankstatement" ? `${currentDoctype.charAt(0).toUpperCase() + currentDoctype.slice(1)}s` : "Bank Statements"}
            </button>
            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
              <li><a className="dropdown-item" onClick={() => { handleDropdownClick("invoice"); navigate('/documentprocessing/invoice') }}>Invoice</a></li>
              <li><a className="dropdown-item" onClick={() => { handleDropdownClick("bankstatement"); navigate('/documentprocessing/bankstatement') }}>Bank Statements</a></li>
              <li><a className="dropdown-item" onClick={() => { handleDropdownClick("handwritten"); navigate('/documentprocessing/handwritten') }}>Handwritten</a></li>
            </ul>
          </span>
        </span>
      </h3>
    </div>
  );
};
