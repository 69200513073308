/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { statistic } from "../../../../app/api";
import { useAuth } from "../../../../app/modules/auth";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  image?: string;
  tag?: string;
};

const MixedWidgetDocument: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  image,
  tag
}) => {
  type MyObject = {
    data: {
      processed_count: number;
      unprocessed_count: number;
      total_count: number;
    };
  };
  const [statisticsData, setStatisticsData] = useState<MyObject>({
    data: {
      processed_count: 0,
      unprocessed_count: 0,
      total_count: 0
    }
  });
  const [fetching, setFetching] = useState(false)

  const { currentUser } = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (tag) {
      setFetching(true)
      statistic({ useremail: currentUser?.email, tag: tag })
        .then((res) => {
          if (res && res.data) {
            setStatisticsData({
              data: {
                processed_count: Number(res.data?.processed_count) || 0,
                unprocessed_count: Number(res.data?.unprocessed_count) || 0,
                total_count: Number(res.data?.total_count) || 0
              }
            });
          } else {
            setStatisticsData({
              data: {
                processed_count: 0,
                unprocessed_count: 0,
                total_count: 0
              }
            });
          }
          setFetching(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle API call error if necessary
        });
    }
  }, [tag]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className="d-flex flex-stack">
            <h3 className="m-0 text-white fw-bold fs-1">
              {title}
            </h3>
            <div className="ms-1">
              {/* begin::Menu */}
              <button
                type="button"
                className={`btn btn-sm btn-icon btn-primary`}
                onClick={(e) => navigate(`/statistics/${tag}`)}
              >
                <img src={toAbsoluteUrl("/media/custom-icon/graph-icon.png")} alt="Thirdeye data" height={30} /> &nbsp;
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className="d-flex text-center flex-column text-white pt-8">
            {/* <span className="fw-semibold fs-7">Total API Call</span> */}
            <span className="fw-bold fs-5 pt-1">{description}</span>
          </div>
        </div>

        <div
          className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
          style={{ marginTop: "-100px" }}
        >
          {
            fetching ?
              <div className="d-flex align-items-center justify-content-center">
                <div className="fs-4 fw-bold text-center">Fetching Analytics &nbsp;</div>
                <ThreeDots
                  visible={true}
                  height="40"
                  width="40"
                  color="#0D6EFD"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div> :
              <div className="">
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="compass" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Processed Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1"> {statisticsData?.data?.processed_count}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="category" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Unprocessed Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData?.data?.unprocessed_count}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <KTIcon iconName="document" className="fs-1" />
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a
                        href="#"
                        className="fs-5 text-gray-800 text-hover-primary fw-bold"
                        style={{ margin: '0', opacity: 0.5 }}
                      >
                        Total Count
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData?.data?.total_count}</div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div >
  );
};

export { MixedWidgetDocument };

