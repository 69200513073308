/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ChatInner, Dropdown1 } from '../../../../../_metronic/partials'
import { resetContest } from '../../../../api'
import { useAuth } from '../../../auth'

const Private: FC = () => {
  const [dataSource, setDataSource] = useState('idp')
  const { currentUser } = useAuth()

  return (
    <div className='d-flex flex-column flex-lg-row mt-5'>
      {/* <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Search by username or email...'
              />
            </form>
          </div>

          <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                      M
                    </span>
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Melody Macy
                    </a>
                    <div className='fw-bold text-gray-400'>melody@altbox.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>5 hrs</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Max Smith
                    </a>
                    <div className='fw-bold text-gray-400'>max@kt.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>20 hrs</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-5.jpg')} />
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Sean Bean
                    </a>
                    <div className='fw-bold text-gray-400'>sean@dellito.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>20 hrs</span>
                  <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-25.jpg')} />
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Optira Bot
                    </a>
                    <div className='fw-bold text-gray-400'>brian@exchange.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>20 hrs</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-warning text-warning fs-6 fw-bolder'>
                      M
                    </span>
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Mikaela Collins
                    </a>
                    <div className='fw-bold text-gray-400'>mikaela@pexcom.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>1 day</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-9.jpg')} />
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Francis Mitcham
                    </a>
                    <div className='fw-bold text-gray-400'>f.mitcham@kpmg.com.au</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>5 hrs</span>
                  <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                      O
                    </span>
                    <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Olivia Wild
                    </a>
                    <div className='fw-bold text-gray-400'>olivia@corpmail.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>1 week</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-primary text-primary fs-6 fw-bolder'>
                      N
                    </span>
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Neil Owen
                    </a>
                    <div className='fw-bold text-gray-400'>owen.neil@gmail.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>20 hrs</span>
                  <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-23.jpg')} />
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Dan Wilson
                    </a>
                    <div className='fw-bold text-gray-400'>dam@consilting.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>2 weeks</span>
                  <span className='badge badge-sm badge-circle badge-light-warning'>9</span>
                </div>
              </div>

              <div className='separator separator-dashed d-none'></div>

              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                      E
                    </span>
                    <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
                  </div>

                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      Emma Bold
                    </a>
                    <div className='fw-bold text-gray-400'>emma@intenso.com</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>1 day</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10 mx-9 mt-8'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  href='#'
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                  Optira's Custom ChatGPT
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-n3'>
                {/* <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
                <Dropdown1 /> */}

                <button className='btn btn-sm'>
                  <select
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    data-kt-user-table-filter="role"
                    data-hide-search="true"
                    value={dataSource ? dataSource : ''}
                    onChange={(e) => {
                      setDataSource(e.target.value);
                    }}
                  >
                    <option value="">-- Select GPT Type --</option>
                    {(!currentUser || currentUser?.resume_search_service == 1) &&  <option value="resumesearch">GPT for Resume Search</option>}
                    {(!currentUser || currentUser?.chatgpt_service == 1 || currentUser?.document_service == 1) && <option value="idp">GPT for IDP</option>}
                  </select>
                </button>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  title='Clear Context'
                  onClick={(e) => { resetContest({ dataSource: dataSource ? dataSource : '' }).then((res) => { alert(res.data) }) }}
                >
                  <KTIcon iconName='eraser' className='fs-2' />
                </button>
                {/* <button
                  type="button"
                  className='btn btn-sm btn-icon btn-active-light-primary mx-2'
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >

                  <KTIcon iconName='filter' className='fs-2' />
                </button>
                <div
                  className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                >
                  <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bolder">Filter Data Source</div>
                  </div>
                  <div className="separator border-gray-200"></div>
                  <div className="px-7 py-5" data-kt-user-table-filter="form">
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">Data Source:</label>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="role"
                        data-hide-search="true"
                        value={dataSource ? dataSource : ''}
                        onChange={(e) => {
                          setDataSource(e.target.value);
                        }}
                      >
                        <option value="">-- Select File Type --</option>
                        <option value="snowflake">Snowflake</option>
                        <option value="sharepoint">Azure Blob</option>
                        <option value="combined">Combined</option>
                      </select>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-success fw-bold px-6"
                        data-kt-menu-dismiss="true"
                        data-kt-user-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <ChatInner datasource={dataSource ? dataSource : ''} />
        </div>
      </div>
    </div>
  )
}

export { Private }
