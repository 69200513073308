import React, { useEffect, useState } from 'react'
import { useAuth } from '../auth';
import { documentAssignTo, getAllMember } from '../../api';
import { AssignDocumentProps, TeamMemberObject } from './teams';
import { ThreeDots } from 'react-loader-spinner';

function AssignDocument({ selectedFiles, setSelectedFiles, setProcessAction, setRefresh }: AssignDocumentProps) {
    const { currentUser } = useAuth();
    const [teams, setTeams] = useState([]);
    const [teamMember, setTeamMember] = useState<string>();
    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        setFetching(true)
        getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
            //  Filtering out emails of objects with type !== 'superadmin'
            const emails = res.data
                .filter((user: TeamMemberObject) => user.type !== 'superadmin')
                .map((user: TeamMemberObject) => user.email);
            setTeams(emails)
            setFetching(false)
        });
    }, [])

    const handleAssingMember = (e: any) => {
        // e.preventDefault()
        if (selectedFiles.length > 0) {
            setProcessAction("assinging")
            let files: Array<Object> = [];
            selectedFiles.forEach((selectedFile) => {
                let fileObj = {
                    file_url: selectedFile
                };
                files.push(fileObj);
            });
            documentAssignTo({
                useremail: currentUser?.email,
                files: files,
                assignTo: teamMember
            }).then((res) => {
                setProcessAction(null)
                setSelectedFiles([]);
                alert(res.message)
                setRefresh(true)
            })
        } else {
            alert("No file selected")
        }
    }

    return (
        <div className=''>
            {
                fetching ?
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="fs-4 fw-bold text-center">Fetching Team Data &nbsp;</div>
                        <ThreeDots
                            visible={true}
                            height="40"
                            width="40"
                            color="#0D6EFD"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div> :
                    <div className="modal-body scroll-y p-2">
                        <div
                            className={`card card-xl-stretch mb-2 px-2`}
                            style={{ maxHeight: "60vh", overflowY: "scroll" }}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <div className="row mb-6">
                                        <label className="col-lg-4 col-form-label fw-bold fs-4 mt-5">
                                            <span className="">Team Member</span>
                                        </label>

                                        <div className="col-lg-8 fv-row">
                                            <small className="px-2 fs-8">Select team member who will process or validate the document</small>

                                            {
                                                teams &&
                                                <select
                                                    className="form-select form-select-solid"
                                                    data-kt-select2="true"
                                                    data-placeholder="Select option"
                                                    data-allow-clear="true"
                                                    value={teamMember}
                                                    onChange={(e) => { setTeamMember(e.target.value) }}
                                                >
                                                    <option value="">---Assign Team Member---</option>
                                                    {teams.map((team) => (
                                                        <option value={team}>{team}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="my-2 px-2 text-center d-flex justify-content-end">
                            <button
                                className="btn btn-lg btn-success"
                                data-bs-dismiss="modal"
                                onClick={(e) => handleAssingMember(e)}
                            >
                                Assign
                            </button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default AssignDocument