import ChatPage from '../../modules/apps/chat/ChatPage'

type Props = {}

export default function CustomConversation({}: Props) {
  return (
    <div style={{paddingTop: '40px'}}>
        <ChatPage />
    </div>
  )
}