/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGoogleLogin } from '@react-oauth/google'
import { useCallback, useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import useDrivePicker from 'react-google-drive-picker'
import { useParams } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import { downloadGdriveFile, uploadFile, uploadFileDemo } from '../../api'
import { useAuth } from '../auth'

type Props = {
  closeModal?: boolean
  // doctype?: string
}
const HalDatasetForm: React.FC<Props> = ({ closeModal }) => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()
  const { doctype } = useParams()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [tag, setTag] = useState<string>('')
  const [company, setCompany] = useState<string>('ces')
  const [bank, setBank] = useState<string>('')
  const [pdfFile, setPdfFile] = useState<File>()
  const [fileName, setFileName] = useState<Array<string>>([])
  const [driveFiles, setDriveFiles] = useState<Array<string>>([])
  const [openPicker, data] = useDrivePicker()
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const loginToken = useGoogleLogin({
    // scope: "https://www.googleapis.com/auth/drive",
    // responseType: 'code',
    // accessType: 'offline',
    // flow: 'auth-code',
    // state: 'none',
    // client_id: "365521252893-1c16vfqbh1pd8f8c0r2mk09s86q30ovh.apps.googleusercontent.com",
    scope: 'https://www.googleapis.com/auth/drive.readonly',
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse)
      let payloadObj = {
        token: tokenResponse?.access_token,
        userName: currentUser?.username,
        userEmail: currentUser?.email,
      }
      openPicker({
        clientId: '291031745538-ctcmqr6oeb3ln439d59t7t7v4tsm0s3h.apps.googleusercontent.com',
        developerKey: 'AIzaSyBua8AigQ18KSQc6SfJ7viZK1Y5tJU5PcE',
        viewId: 'DOCS', // Set viewId to 'FOLDERS' to enable folder selection
        // viewMimeTypes: "application/vnd.google-apps.folder,application/json",
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        token: tokenResponse?.access_token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          if (data.action === 'cancel') {
            console.log('User clicked cancel/close button')
          }
          console.log(data)
          if (data.docs.length == 1 && data.docs[0].type === 'folder') {
            const folderId = data.docs[0].id
            const type = data.docs[0].type
            console.log('Selected folder ID:', folderId)
            downloadGdriveFile({
              ...payloadObj,
              docId: folderId,
              type: type,
            }).then((res) => {
              console.log(res)
              window.location.reload()
            })
          }
          if (data.docs.length > 1) {
            let tempDriveFiles: string[] = []
            data.docs.map((doc) => {
              // Add other file IDs to the tempDriveFiles array if needed
              tempDriveFiles.push(doc.id)
            })
            console.log(tempDriveFiles)
            downloadGdriveFile({
              ...payloadObj,
              docId: tempDriveFiles,
              type: 'file',
            }).then((res) => {
              console.log(res)
            })
            setDriveFiles([...tempDriveFiles])
          }
        },
      })
    },
  })

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      // Handle file changes here
      console.log(acceptedFiles)
      if (acceptedFiles) {
        const fileList = Array.from(acceptedFiles)
        let tempFileNames: string[] = []
        acceptedFiles.map((file) => {
          tempFileNames.push(file.name)
        })
        setFileName([...tempFileNames])
        let seletedFile = acceptedFiles[0]
        setPdfFile(seletedFile)
        setSelectedFiles(fileList)
      }
    },
    []
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmit = (event: React.FormEvent) => {
    setLoading(true)
    event.preventDefault()
    let enableProcess = true

    if (selectedFiles.length > 0) {
      const formData = new FormData()
      let fileArr = []
      selectedFiles.forEach((file, index) => {
        formData.append('files', file, `${file.name}`)
      })
      formData.append('userName', currentUser ? currentUser.username : 'Singhi')
      formData.append('userEmail', currentUser ? currentUser.email : 'singhi@thirdeyedata.ai')

      if (currentUser?.username == 'Texisle') {
        formData.append('company', company)
      }

      if (currentUser?.username === 'HAL') {
        formData.append('subFolder', 'Servicing')
      }
      doctype ? formData.append('tag', doctype) : formData.append('tag', 'resume')

      // Use the formData object as needed, such as sending it via AJAX or API request
      // Example using fetch API:
      // if (enableProcess) {
      //   if (currentUser?.username == 'HAL') {
      //     uploadFile(formData)
      //       .then((response: any) => {
      //         setLoading(false)
      //         console.log(response)
      //         window.location.reload()
      //         response.json()
      //       })
      //       .then((data) => {})
      //       .catch((error) => {
      //         // Handle error
      //       })
      //   } else {
      //     uploadFileDemo(formData)
      //       .then((response) => {
      //         setLoading(false)
      //         console.log(response)
      //         window.location.reload()
      //         response.json()
      //       })
      //       .then((data) => {})
      //       .catch((error) => {
      //         // Handle error
      //       })
      //   }
      // }
      setTimeout(() => {
        setLoading(false)
        window.location.reload()
      }, 2000);
    } else {
      setLoading(false)
      alert('You need at least one file uploaded')
    }
  }

  useEffect(() => {
    console.log(closeModal)
    console.log(fileName)
    if (closeModal) {
      let tempArr: string[] = []
      console.log(fileName)
      setFileName(tempArr)
      setSelectedFiles([])
    }
  }, [closeModal])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
    >
      <div className='mb-10'>
        {/* <label className="form-label fw-bold">Document Type: </label>
        <select
          className="form-select form-select-solid"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        >
          <option value="">---Select Document Type---</option>
          <option value="Bank Statement">Bank Statement</option>
          <option value="Contract">Contract</option>
          <option value="Invoice">Invoice</option>
        </select> */}
        {/* {tag == "Bank Statement" && (
          <>
            <label className="form-label fw-bold">Document Type: </label>
            <select
              className="form-select form-select-solid"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              value={bank}
              onChange={(e) => setBank(e.target.value)}
            >
              <option value="">---Select Bank---</option>
              <option value="hdfc_model1">HDFC Bank</option>
              <option value="kotak_model1">Kotatk Bank</option>
            </select>
          </>
        )} */}

        {/* {
          doctype == "invoice" && (
            <>
              <label className="form-label fw-bold">Invoice Company: </label>
              <select
                className="form-select form-select-solid"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              >
                <option value="">---Select Document Type---</option>
                <option value="ces">Columbia Electric Supply</option>
                <option value="martinmarietta">Martin Marietta</option>
                <option value="mbs">McCoy's Building Supply</option>
                <option value="texislesupply">Texisle Supply</option>
                <option value="sps">Saber Power Supply</option>
              </select>
            </>
          )
        } */}
      </div>

      {/* <div className="separator separator-content my-14">
        <span className="w-125px text-gray-500 fw-semibold fs-7">Or</span>
      </div> */}

      <div
        {...getRootProps()}
        style={{
          border: '2px dashed',
          padding: '80px 40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: "#1b1b29",
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} className='form-control form-control-lg form-control-solid' />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            {fileName.length <= 0 ? (
              <span className='fw-bold text-dark'>
                Drag media here to upload or connect an account...
              </span>
            ) : (
              <div className='d-flex flex-column'>
                {fileName.map((file: string) => (
                  <div className='badge badge-primary m-1'>
                    <KTIcon iconName='file' className='fs-3' /> &nbsp; {file}
                  </div>
                ))}
              </div>
            )}
          </p>
        )}
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or</span>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-12'>
          <div
            onClick={() => loginToken()}
            data-bs-dismiss='modal'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            Connect to an external datasource
          </div>
          {/* <GoogleLogin
            clientId={
              "365521252893-ro7sdp4htqo8p1h7desmtesvleq777ve.apps.googleusercontent.com"
            }
            responseType="code"
            accessType="offline"
            prompt="consent"
            scope="https://www.googleapis.com/auth/drive.readonly"
            onSuccess={onSuccess}
            onFailure={onFailure}
            buttonText="Sign in with Google"
          /> */}
          {/* <GoogleDriveApp /> */}
        </div>
      </div>

      {/* begin::Form group */}
      <div className='text-center mt-5'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-success w-100 mb-5'
          onClick={handleSubmit}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export { HalDatasetForm }
