/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { TeamTableProps } from './teams'

const TeamTable: React.FC<TeamTableProps> = ({ className, userDetails, setUpdateUser, setUserAnalytics }) => {

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>User Name</th>
                <th className='min-w-125px'>User Email</th>
                <th className='min-w-125px'>User Type</th>
                <th className='min-w-125px'>Organization</th>
                <th className='min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {userDetails.map((user, index) => (
                <tr key={index}>
                  <td className='px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <KTIcon iconName='user' className="fs-3x text-dark" />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user.username}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.email}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.type}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.organization_name}
                    </a>
                  </td>
                  <td className='d-flex  justify-content-end'>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-3 my-3 w-180px "
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_team"
                      onClick={(e) => setUpdateUser(user)}
                    >
                      Edit Member
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-3 my-3 w-180px "
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_team_analytics"
                      onClick={(e) => setUserAnalytics(user)}
                    >
                      View Analytics
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TeamTable }

