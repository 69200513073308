import axios from 'axios';
// export const baseUri = '${API_URL}/app';

const API_URL = process.env.REACT_APP_API_URL
const API_URL_TRAINING = process.env.REACT_APP_API_URL_TRAINING
// const API_URL = "https://demo.optira.ai:8000"
// const API_URL = "https://f054-27-131-210-250.ngrok.io"
// const API_URL = "http://localhost:8000"

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     // Handle network errors or server-side errors
//     alert('A network or server-side error occurred:', error);
//     throw error;
//   }
// );


// User Management APIs

export const memberRegister = async (admin) => {
  try {
    const { data } = await axios.post(`${API_URL}/register_user/`, admin);
    return data;
  } catch (error) {
    // Handle error
    if (error.response) {
      console.error('Error response status:', error.response.status);
      console.error('Error response data:', error.response.data);
      throw new Error(error.response.data.message || 'Unknown error occurred');
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from server');
    } else {
      console.error('Request setup error:', error.message);
      throw new Error('Request setup error');
    }
  }
};

export const getAllMember = async (admin) => {
  const { data } = await axios.post(`${API_URL}/get_user_by_org_id/`, admin);
  return data;
};

export const register = async (admin) => {
  const { data } = await axios.post(`${API_URL}/register_user/`, admin);
  return data;
};

export const login = async (admin) => {
  const { data } = await axios.post(`${API_URL}/login`, admin);
  return data;
};

export const getUserByToken = async (token) => {
  const { data } = await axios.post(`${API_URL}/get_user_by_token`, token);
  return data;
};

export const updateUser = async (user) => {
  const { data } = await axios.post(`${API_URL}/update_user/`, user);
  return data;
};

// --------------------------------------------------------------------------------------------------

// Analytics APIs

export const statistic = async (query) => {
  const { data } = await axios.post(`${API_URL}/statistic_data/`, query);
  return data;
}

export const datestatistic = async (query) => {
  const { data } = await axios.post(`${API_URL}/datewise_data/`, query);
  return data;
}

export const memberStatistic = async (query) => {
  const { data } = await axios.post(`${API_URL}/assigned_user_data/`, query);
  return data;
}



// --------------------------------------------------------------------------------------------------

// G Drive Automation

export const gdriveCount = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_file_counts/`, query);
  return data;
}

export const gdriveTrack = async (query) => {
  const { data } = await axios.post(`${API_URL}/monitor_folder/`, query);
  return data;
}

export const getGDriveInfo = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_gdrive_info/`, query);
  return data;
}

export const driveCreateSource = async (admin) => {
  const { data } = await axios.post(`${API_URL}/insert_gdrive_folder_info/`, admin);
  return data;
};

export const startContinuousProcess = async () => {
  const { data } = await axios.post(`${API_URL}/monitor_folder_test/`);
  return data;
};


// --------------------------------------------------------------------------------------------------

// Intelligent Document Processing

export const filesByUser = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_folder_name/`, query);
  return data;
};

export const uploadFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_multiple_doc/`, query);
  return data;
};

export const uploadFileDemo = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_multiple_documents/`, query);
  return data;
};

export const processMultipleFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/process_multiple_document/`, query);
  return data;
};

export const processDocuments = async (query) => {
  const { data } = await axios.post(`${API_URL}/process_documents/`, query);
  return data;
};

export const documentAssignTo = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/assign_to/`, query);
  return data;
};

export const documentApproval = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/approval/`, query);
  return data;
};

export const documentFeedback = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/feedback/`, query);
  return data;
};

export const updateDownloadCount = async (admin) => {
  const { data } = await axios.post(`${API_URL}/download_count/`, admin);
  return data;
};

export const savePdfPreview = async (admin) => {
  const { data } = await axios.post(`${API_URL}/save_pdf_for_preview/`, admin);
  return data;
};

export const downloadGdriveFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/monitor_folder/`, query);
  return data;
};

export const setGdriveDestination = async (query) => {
  const { data } = await axios.post(`${API_URL}/update_destination_folder/`, query);
  return data;
};


// --------------------------------------------------------------------------------------------------

// Audio Processing

export const uploadAudioFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_recording_file/`, query);
  return data;
};

export const getAudioFiles = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_audio_file_details/`, query);
  return data;
};

export const processAudioFiles = async (query) => {
  const { data } = await axios.post(`${API_URL}/process_audio_file/`, query);
  return data;
};


// --------------------------------------------------------------------------------------------------

// Resume Formating

export const uploadResume = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_multiple_resume/`, query);
  return data;
};

export const processResume = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/resume_processing/`, query);
    return data;
  } catch (error) {
    // Handle error
    console.error('An error occurred while processing the resume:', error);
    throw error; // Re-throw the error to propagate it further if necessary
  }
};

export const processResumeForum = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/forum_process/`, query);
    return data;
  } catch (error) {
    // Handle error
    console.error('An error occurred while processing the resume:', error);
    throw error; // Re-throw the error to propagate it further if necessary
  }
};

export const getResumeForumData = async (query) => {
  try {
    const { data } = await axios.post(`${API_URL}/forum_processed_data/`, query);
    return data;
  } catch (error) {
    // Handle error
    console.error('An error occurred while processing the resume:', error);
    throw error; // Re-throw the error to propagate it further if necessary
  }
};

export const bulkResumeProcess = async (query) => {
  const { data } = await axios.post(`${API_URL}/bulk_resume_process/`, query);
  return data;
};

export const getResumeInfo = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_resume_info/`, query);
  return data;
};

export const resumeMove = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_all_resume/`, query);
  return data;
};

export const resumeAssignTo = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/resume_assign_to/`, query);
  return data;
};

export const resumeApproval = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/resume_approval/`, query);
  return data;
};

export const resumeFeedback = async (query) => {
  const { data } = await axios.post(`${API_URL_TRAINING}/resume-feedback/`, query);
  return data;
};


// --------------------------------------------------------------------------------------------------

// Custom Chat GPT

export const custom_chat_gpt_demo = async (query) => {
  const { data } = await axios.post(`${API_URL}/custom_mysql_chat_gpt_session/`, query);
  return data;
};

export const resetContest = async (query) => {
  const { data } = await axios.post(`${API_URL}/reset_context/`, query);
  return data;
};

export const updateFeedback = async (query) => {
  const { data } = await axios.post(`${API_URL}/update_feedback/`, query);
  return data;
};


// -------------------------------------------------------------------------------------------------

// Sharman API

export const sharmanAllFiles = async () => {
  const { data } = await axios.get(`${API_URL}/all_sharman_files/`);
  return data;
};

export const customSearch = async (query) => {
  const { data } = await axios.post(`${API_URL}/custom_search/`, query);
  return data;
};

export const sharmanSemantic = async (query) => {
  const { data } = await axios.post(`${API_URL}/search_and_summarize/`, query);
  return data;
};

export const allCategories = async (query) => {
  const { data } = await axios.post(`${API_URL}/all_categories/`, query);
  return data;
};

export const categorySearchByFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/category_search_by_file/`, query);
  return data;
};

export const fileSearchByCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/file_search_by_category/`, query);
  return data;
};

// --------------------------------------------------------------------------------------------------

// Singhi API

export const getClientFileList = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_all_clients_file_list_singhi/`, query);
  return data;
};

export const deleteFiles = async (query) => {
  const { data } = await axios.post(`${API_URL}/delete_files/`, query);
  return data;
};

export const getClientListSinghi = async (client) => {
  const { data } = await axios.post(`${API_URL}/get_client_list_singhi/`, client);
  return data;
};

export const storeTrainingData = async (query) => {
  const { data } = await axios.post(`${API_URL}/StoreTrainingData/`, query);
  return data;
};

export const countClient = async (pefix) => {
  const { data } = await axios.post(`${API_URL}/count_all_clients_singhi/`, pefix);
  return data;
};

export const forgotPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/forgot_password_singhi/`, admin);
  return data;
};

// export const processMultipleFile = async (query) => {
//   const { data } = await axios.post(`${API_URL}/schedule_process_singhi/`, query);
//   return data;
// };

export const cronjob_data = async (query) => {
  const { data } = await axios.post(`${API_URL}/cronjob_data/`, query);
  return data;
};

// export const uploadFile = async (query) => {
//   const { data } = await axios.post(`${API_URL}/upload_multiple_doc_singhi/`, query);
//   return data;
// };

export const resetPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/reset_password_singhi/`, admin);
  return data;
};


// --------------------------------------------------------------------------------------------------

// Other API

export const websiteCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/web_cat/`, query);
  return data;
};

export const documentSummary = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_summary_from_url/`, query);
  return data;
};


// --------------------------------------------------------------------------------------------------

// HAL API


export const fileTextExtraction = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_keyword_from_url/`, query);
  return data;
};

export const sqlDataCreate = async (query) => {
  const { data } = await axios.post(`${API_URL}/hal_data_create/`, query);
  return data;
};


export const generateCsv = async (query) => {
  const { data } = await axios.post(`${API_URL}/make_hal_excel/`, query);
  return data;
};